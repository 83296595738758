import React from 'react';
import dateHelper from '../util/dateHelper';

const FeaturedLinkTitle = ({
	linkType,
	title,
	artist,
	exhibitionLocation,
	exhibitionLocationOther,
	endDate,
	startDate,
	subtitle,
}) => (
	<div className="pb2">
		{linkType === 'exhibition' && (
			<div>
				<p className="sans--24 sans--36--md">{artist}</p>
				<p className="sans--14 sans--18--md">
					{title}
					{exhibitionLocationOther && (
						<span className="db sans--14 sans--18--md">
							Location: {exhibitionLocationOther}
						</span>
					)}
					{exhibitionLocation && !exhibitionLocationOther && (
						<span className="db sans--14 sans--18--md">
							{exhibitionLocation === 'downtown-detroit' && 'Location: 1260 Library Street, Detroit'}
							{exhibitionLocation === 'the-shepherd' && 'Location: the Shepherd'}
						</span>
					)}
					{subtitle && (
						<span className="db sans--14 sans--18--md">
							{subtitle}
						</span>
					)}
					{endDate && startDate && (
						<span className="db sans--14 sans--18--md">
							{`${dateHelper(startDate)} — ${dateHelper(
								endDate
							)}`}
						</span>
					)}
				</p>
			</div>
		)}
		{(linkType === 'site' ||
			linkType === 'anatomy' ||
			linkType === 'queue') && (
			<div>
				<p className="sans--24 sans--36--md pr2">
					{linkType === 'site' && 'SITE: '}
					{linkType === 'queue' && 'QUEUE: '}
					{linkType === 'anatomy' && 'Anatomy: '}
					{title}
				</p>
				{subtitle && (
					<p className="sans--14 sans--18--md">{subtitle}</p>
				)}
				{linkType === 'queue' && endDate && startDate && (
					<span className="db sans--14 sans--18--md">
						{`${dateHelper(startDate)} — ${dateHelper(endDate)}`}
					</span>
				)}
			</div>
		)}
		{linkType === 'project' && (
			<div className="pr2">
				<p className="sans--24 sans--36--md">{artist}</p>

				<p className="sans--14 sans--18--md">{title}</p>

				{subtitle && (
					<p className="sans--14 sans--18--md">{subtitle}</p>
				)}
			</div>
		)}
		{linkType === 'alliance' && (
			<div className="pr2">
				<p className="sans--24 sans--36--md">Alliance: {title}</p>
				{title !== artist && (
					<p className="sans--14 sans--18--md">{artist}</p>
				)}
				{subtitle && (
					<p className="sans--14 sans--18--md">{subtitle}</p>
				)}
				{endDate && startDate && (
					<span className="db sans--14 sans--18--md">
						{`${dateHelper(startDate)} — ${dateHelper(endDate)}`}
					</span>
				)}
			</div>
		)}
		{linkType === 'artist' && (
			<div className="pr2">
				<p className="sans--24 sans--36--md">{title}</p>
				{subtitle && (
					<p className="sans--14 sans--18--md">{subtitle}</p>
				)}
			</div>
		)}
		{linkType === 'page' && (
			<p className="sans--24 sans--36--md">{title}</p>
		)}
	</div>
);


export default FeaturedLinkTitle;
