import { parseISO } from 'date-fns';

const dateHelper = date => {
	const parsedDate = parseISO(date, '');

	let month;

	switch (parsedDate.getMonth()) {
		case 0:
			month = 'January';
			break;
		case 1:
			month = 'February';
			break;
		case 2:
			month = 'March';
			break;
		case 3:
			month = 'April';
			break;
		case 4:
			month = 'May';
			break;
		case 5:
			month = 'June';
			break;
		case 6:
			month = 'July';
			break;
		case 7:
			month = 'August';
			break;
		case 8:
			month = 'September';
			break;
		case 9:
			month = 'October';
			break;
		case 10:
			month = 'November';
			break;
		case 11:
			month = 'December';
			break;

		default:
			month = '';
			break;
	}

	const day = parsedDate.getDate();
	const year = parsedDate.getFullYear();

	return `${month} ${day}, ${year}`;
};

export default dateHelper;
